@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Raleway:wght@400;600;700&display=swap");

.parting p,
.parting h1 {
  font-family: "Montserrat", Helvetica, sans-serif;
}

.approach {
  margin-top: 5rem;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 700;
  background: -webkit-linear-gradient(right, #93291E, #ED213A) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.advertising {
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: normal;
  color: rgb(45, 45, 45);
  font-size: 1.3rem;
  font-style: italic;
  margin-top: 0.5rem;
  margin-bottom: 5rem;
}

.information {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instagram {
  width: 20rem;
  object-fit: contain;
}

.paragraph {
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 3rem;
  text-align: left;
}

.paragraphs {
  width: 80%;
}

@media screen and (min-width: 768px) {
  .paragraphs {
    width: 55%;
  }
}

@media screen and (min-width: 1000px) {
  .information {
    padding: 0 6rem;
    flex-direction: row;
    align-items: flex-start;
  }

  .instagram {
    width: 45%;
    margin-left: 4rem;
  }

  .approach {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1600px) {
  .information {
    padding: 0 25rem;
  }
}
