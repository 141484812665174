@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Raleway:wght@400;600;700&display=swap");

img {
  width: 10rem;
  display: flex;
}

.logo {
  margin-top: 1rem;
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: -0.5rem;
  
}

.main-body {
  padding: 1rem;
}

p, .main-body h1 {
  text-align: left;
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: 700;
}

.header {
  color: rgb(184, 184, 184);
  font-size: 2rem;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
}

.Info {
  font-size: 2.7rem;
  background: -webkit-linear-gradient(right, #93291E, #ED213A) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  margin-top: 1rem;
}

.bookButton {
  background-image: linear-gradient(270deg, #93291E, #ED213A) !important;
  box-shadow: 0px 10px 22px 0px rgb(86 204 242 / 44%);
  color: white;
  border: none;
  border-radius: 60px;
  padding: 15px 25px;
  cursor: pointer;
  transition: box-shadow 0.1s, background-image 5s;
  width: 100%;
}

.bookButton:hover {
  box-shadow: none;
  background-image: linear-gradient(270deg, #81241a, #ce1c31) !important;
}

button p {
  text-align: center;
  font-family: "Montserrat", Helvetica, sans-serif !important;
  margin: 0.3rem;
}

.interactives {
  display: flex;
  flex-direction: column;
}

.bookcall {
  font-weight: 700;
  font-size: 1.5rem;
}

.bookcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickhere {
  font-weight: 500;
  font-size: 1rem;
  color: white;
  opacity: 0.7;
}

.section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.computer {
  width: 50%;
  margin-left: 5rem;
  display: none;
}

.computer1 {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
  .section {
    flex-direction: row;
  }

  .computer {
    display: block;
    margin-bottom: -2rem;
    margin-top: 1rem;
  }

  .computer1 {
    display: none;
  }

  .main-body {
    padding: 1rem 6rem 4rem 6rem;
  }
}
