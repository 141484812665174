@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Raleway:wght@400;600;700&display=swap");

.appointment p {
  font-family: "Montserrat", Helvetica, sans-serif;
  line-height: 1.5rem;
}
.appointment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 3rem;
  align-items: center;
}

.widget {
  width: 100%;
  height: 50rem;
}

.schedule-info {
  width: 80%;
}

.schedule-info img {
  width: 16px;
  margin-right: 1rem;
}

ul {
  list-style: none;
  padding: 0;
  font-weight: normal;
}

li {
  display: flex;
}

li p {
  font-weight: normal;
}

.sched-w-kaveh {
  font-weight: 700;
  font-size: 1.5rem;
  color: rgb(71,71,71);
  margin-bottom: 0;
}

.free-demo {
    font-size: 2.5rem;
    margin-top: 0.5rem;
    line-height: 3rem;
    background: -webkit-linear-gradient(right, #93291E, #ED213A)!important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
    text-align: left;

}

.text-block {
    font-weight: normal;
    font-size: 1.15rem;
}

.perfect-for {
    text-align: left;
    font-size: 1.3rem;
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;
    background: -webkit-linear-gradient(right, #93291E, #ED213A)!important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
}

@media screen and (min-width: 1024px) {
  .appointment {
    flex-direction: row;
    padding: 5rem 10rem;
  }

  .widget {
    width: 50%;
    height: 100%;
  }

  .schedule-info {
    margin-left: 3rem;
    width: 30%;
  }
}

@media screen and (min-width: 900px) {
    .appointment {
        flex-direction: row;
        padding: 5rem 0.5rem;
      }
}
