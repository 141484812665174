.background {
    background-image: linear-gradient(to right, #e43e3e, #f06f5f);
    width: 100vw;
    padding: 6rem 0;
    margin-top: 3rem;
}

.top-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.revgen {
    margin-bottom: -2rem;
    color: white;
    font-size: 1.75rem;
    text-shadow: rgb(0 0 0 / 5%) 2px 2px 2px;
    text-align: center;

}

.card-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

}

.card1 {
    width: 90%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem 2rem;
    flex-direction: column;
    border-radius: 3rem;
    box-shadow: 20px 20px 60px #7f1616, -20px -20px 60px #d27a7a;


}

.card1 p {
    text-align: center;
    font-family: "Montserrat", Helvetica, sans-serif !important;
    font-weight: 700;

}

.topnum {
    font-size: 4rem;
    margin: 2rem auto;
}

.bottom {
    font-size: 1.5rem;
    margin: 0 auto 2rem auto;
}



@media screen and (min-width: 768px) {
    .card1 {
        width: 20%;
    }

    .revgen {
        font-size: 3rem;
    }

    .topnum {
        font-size: 5rem;
    }
  }



@media screen and (min-width: 768px) {
    .card-div {
        flex-direction: row;
    }
  }


